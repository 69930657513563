// @ts-nocheck
import { useLoader } from '@react-three/fiber';
import React, { useEffect, useState } from 'react';
import { TextureLoader } from 'expo-three';
import {
  balloonBlue,
  balloonGreen,
  balloonOrange,
  balloonPink,
  balloonYellow,
  heartBlue,
  heartGreen,
  heartOrange,
  heartPink,
  heartYellow,
  starGreen,
  starOrange,
  starPink,
  starYellow,
  moonBlue,
  moonGreen,
  moonOrange,
  moonPink,
  moonYellow,
  candyBlue,
  candyGreen,
  candyOrange,
  candyPink,
  candyYellow,
  starBlue,
} from '../assets/balloons';
import ExplosionConfetti from './Confetti';
export const wrongIcon = require(`./../assets/wrong-icon.png`);
export const rightIcon = require(`./../assets/right-icon.png`);


const assets: Record<string, string> = {
  balloon_orange: balloonOrange,
  balloon_blue: balloonBlue,
  balloon_green: balloonGreen,
  balloon_pink: balloonPink,
  balloon_yellow: balloonYellow,
  candy_orange: candyOrange,
  candy_blue: candyBlue,
  candy_green: candyGreen,
  candy_yellow: candyYellow,
  candy_pink: candyPink,
  heart_orange: heartOrange,
  heart_blue: heartBlue,
  heart_green: heartGreen,
  heart_pink: heartPink,
  heart_yellow: heartYellow,
  moon_orange: moonOrange,
  moon_blue: moonBlue,
  moon_green: moonGreen,
  moon_pink: moonPink,
  moon_yellow: moonYellow,
  star_orange: starOrange,
  star_green: starGreen,
  star_pink: starPink,
  star_yellow: starYellow,
  star_blue: starBlue,
};

const colors: Record<string, string> = {
  blue: 0x30a5ff,
  yellow: 0xdbd060,
  pink: 0xf98485,
  green: 0x17a86f,
  orange: 0xff9a00
}

export enum TargetEnum {
  Left = 'left',
  Right = 'right',
  No = 'no',
}

export interface BallonInterface {
  asset: string;
  screenLocationX: number;
  screenLocationY: number;
  target: TargetEnum;
  interStimulusInterval: number;
  speed: number;
}

export interface Position {
  x: number;
  y: number;
  z: number;
}

export interface BalloonProps {
  initialPosition: Position;
  asset: string;
  speed: number;
  incorrect: number;
  correct: number;
  lastTouchedBalloon: string;
}
const getAssetImage = (asset: string) => {
  const image = assets[asset] || balloonBlue;
  return image;
};

const getColor = (color: string) => {
  const colorCode = colors[color]
  return colorCode
}
export const Balloon = ({ initialPosition, asset, incorrect, correct, lastTouchedBalloon }: BalloonProps) => {
  const { x, y, z } = initialPosition;
  const [showBalloon, setShowBalloon] = useState<boolean>(true);
  const [showConfetti, setShowConfetti] = useState<boolean>(false);
  const [correctNumber, setCorrectNumber] = useState<number>(correct);
  const [incorrectNumber, setIncorrectNumber] = useState<number>(incorrect);
  const [showIncorrect, setShowIncorrect] = useState<boolean>(false);
  const [showCorrect, setShowCorrect] = useState<boolean>(false);
  
  const [color, setColor] = useState<string>(getColor(lastTouchedBalloon?.split("_")[1]));

  const myMesh = React.useRef();
  // @ts-ignore
  const texture = useLoader(TextureLoader, getAssetImage(asset));
  const wrongIconTexture = useLoader(TextureLoader, wrongIcon);
  const rightIconTexture = useLoader(TextureLoader, rightIcon);
  const ref = React.useRef();

  useEffect(() => {
    if(correct !== correctNumber){
      setShowConfetti(true);
      setShowCorrect(true);
      setCorrectNumber(correct);
    }
  }, [correct]);

  useEffect(() => {
    if(incorrect !== incorrectNumber){
      setShowIncorrect(true);
      setShowConfetti(true);
      setIncorrectNumber(incorrect);
    }
  }, [incorrect]);

  useEffect(() => {
    if(showConfetti === true){
      const timer = setTimeout(() => {
        setShowConfetti(false);
      }, 750);
      return () => clearTimeout(timer);
    }
    return;
  }, [showConfetti])

  useEffect(() => {
    if(showCorrect === true){
      const timer = setTimeout(() => {
        setShowCorrect(false);
      }, 750);
      return () => clearTimeout(timer);
    }
    return;
  }, [showCorrect])

  useEffect(() => {
    if(showIncorrect === true){
      const timer = setTimeout(() => {
        setShowIncorrect(false);
      }, 750);
      return () => clearTimeout(timer);
    }
    return;
  }, [showIncorrect])


  useEffect(() => {
    if (z !== 0 && showBalloon === false) {
      setShowBalloon(true);
    }
  }, [z]);

  useEffect(() => {
    const lastTouched = lastTouchedBalloon?.split("_")[1];
    console.log("lastTouched !== color", lastTouched !== color, color, lastTouched)
    // if(lastTouched !== color){
      setColor(lastTouched);
    // }
  }, [color, lastTouchedBalloon])

  const scale = 0.5;

  const renderWrongIcon = (
    <mesh position={[0,0,0]} ref={myMesh} scale={scale}>
      <planeGeometry attach='geometry' args={[1,1]} />
      <meshBasicMaterial attach={'material'} map={wrongIconTexture} transparent />
    </mesh>
  )
  const renderRightIcon = (
    <mesh position={[0,0,0]} ref={myMesh} scale={scale}>
      <planeGeometry attach='geometry' args={[1,1]} />
      <meshBasicMaterial attach={'material'} map={rightIconTexture} transparent />
    </mesh>
  )

  return (
    <>
      {showBalloon ? (
        <mesh position={[x , y, -z]} ref={myMesh} scale={scale}>
          <planeGeometry attach='geometry' args={[1.5, 4]} />
          <meshBasicMaterial attach={'material'} map={texture} transparent />
        </mesh>
      ) : null}
      {showCorrect && renderRightIcon}
      {showIncorrect && renderWrongIcon}
      <ExplosionConfetti isExploding={showConfetti} ref={ref} colors={[color]} />
    </>
  )
};
