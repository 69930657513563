import React from "react";
import { Balloon as BalloonInterface, Hand } from "../api/model/LiveSession";
import { Balloon } from "./Balloon";
import { Hands } from "./Hands";

interface BalloonsProps {
  balloons: BalloonInterface[];
  hands: Hand[];
  incorrect: number;
  correct: number;
  lastTouchedBalloon: string;
}

export const Balloons = ({ balloons, hands, incorrect, correct, lastTouchedBalloon }: BalloonsProps) => {
    let balloonsDraftedList = balloons ? balloons.map((balloon, index) => {
      const { screenLocationX, screenLocationY, speed, asset, screenLocationZ } = balloon;
      return <Balloon initialPosition={{x: screenLocationX, y: screenLocationY, z: screenLocationZ }} asset={asset} speed={speed} key={index} correct={correct} incorrect={incorrect} lastTouchedBalloon={lastTouchedBalloon} />
    }) : [];
    return (
      <React.Fragment>
        {balloonsDraftedList}
        <Hands hands={hands} />
      </React.Fragment>
    )
  }